<template>
  <d-confirm-dialog
    level="info"
    :value="dialog"
    title="Are you sure you wish to log out?"
    @confirm="onLogout"
    @cancel="$emit('onClose')"
  />
</template>
<script>
export default {
  name: 'LogoutDialog',
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onLogout() {
      this.$bus.$emit('app-logout')
      this.$emit('onClose')
    }
  }
}
</script>

<style scoped>
.v-btn {
  min-width: 0;
}
</style>
